import './Icon.pcss';
import type { DefaultComponentType } from '../../../utils/types';

export enum IconPositions {
    LEFT = 'left',
    RIGHT = 'right'
}

export type IconProps = DefaultComponentType & {
    id?: string;
    name: string;
    size?: number;
    custom?: boolean;
};

export default class Icon extends HTMLElement {
    static defaultSize = '1.5';

    static get observedAttributes(): string[] {
        return ['name'];
    }

    get size(): string {
        return this.getAttribute('size') || Icon.defaultSize;
    }

    set size(value: string) {
        this.setAttribute('size', value);
    }

    get name(): string {
        return this.getAttribute('name') || '';
    }

    set name(value: string) {
        this.setAttribute('name', value);
    }

    public constructor(props?: IconProps) {
        super();

        if (props) {
            this.id = props.id || '';
            this.name = props.name;
            this.size = props.size?.toString() || Icon.defaultSize;
            this.style.setProperty('--icon-size', `${this.size}rem`);
            return;
        }

        this.style.setProperty('--icon-size', `${this.size}rem`);

        if (this.name) {
            this.renderIcon(this.name);
        }
    }

    protected async attributeChangedCallback(
        attr: string,
        oldValue: string,
        newValue: string
    ): Promise<void> {
        if (oldValue === newValue) {
            return;
        }

        if (attr === 'name') {
            await this.renderIcon(newValue);
        }
    }

    protected async renderIcon(name: string): Promise<void> {
        try {
            const importedSvg = await import(`../../../assets/icons/${name}.svg?raw`);
            const svg = importedSvg.default;

            if (!svg) {
                return;
            }

            this.classList.add('nh-icon');
            const parser = new DOMParser();
            const something: Document = parser.parseFromString(svg, 'image/svg+xml');
            const svgElement = something.getElementsByTagName('svg')[0] as SVGElement;
            const size = `${Number(this.size) * 16}px`;
            svgElement.setAttribute('height', size);
            svgElement.setAttribute('width', size);
            this.innerHTML = '';

            this.appendChild(svgElement);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`No icon with name ${this.name} was found.`);
        }
    }
}

if (!window.customElements.get('nh-icon-new')) {
    window.customElements.define('nh-icon-new', Icon);
}
