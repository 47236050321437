import '../../../../src/components/protons/icon/Icon';
import type Icon from '../../../../src/components/protons/icon/Icon';
import type { IconPositions, IconProps } from '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import './Button.pcss';

export enum ButtonVariant {
    PRIMARY = 'primary',
    PRIMARY_DANGER = 'primary-danger',
    PRIMARY_ACCENT = 'primary-accent',
    SECONDARY = 'secondary',
    SECONDARY_DANGER = 'secondary-danger',
    MINIMAL = 'minimal',
    GOOGLE = 'google',
    DARK = 'dark',
    FLOATING = 'floating'
}

export enum ButtonSize {
    DEFAULT = 'default',
    INLINE = 'inline',
    SMALL = 'small',
    ICON = 'icon',
    ICON_MOBILE = 'icon-mobile',
    FULL_WIDTH = 'full-width',
    FULL_WIDTH_MOBILE = 'full-width--mobile',
    ATTACHED = 'attached'
}

export enum ButtonType {
    BUTTON = 'button',
    SUBMIT = 'submit'
}

export type ButtonProps = DefaultComponentType & {
    action?: boolean;
    is?: string;
    label?: string;
    type?: ButtonType;
    variant?: ButtonVariant;
    loading?: boolean;
    formId?: string;
    disabled?: boolean;
    size?: ButtonSize;
    icon?: IconProps;
    iconPosition?: IconPositions;
};

export default class Button extends HTMLButtonElement {
    #icon: Icon | null = null;

    static get observedAttributes(): string[] {
        return ['icon'];
    }

    get variant(): string | undefined {
        const variant = this.getAttribute('variant');
        if (variant === null) {
            return undefined;
        }

        const variants = <string[]>Object.values(ButtonVariant);

        return variants.includes(variant) ? variant : undefined;
    }

    set variant(value: string | undefined) {
        const variant = <string[]>Object.values(ButtonVariant);

        if (!value || !variant.includes(value)) {
            return;
        }

        this.classList.remove(`nh-button--${this.variant}`);
        this.removeAttribute('variant');

        this.setAttribute('variant', value);
        this.classList.add(`nh-button--${value}`);
    }

    get size(): string | undefined {
        const size = this.getAttribute('size');
        if (size === null) {
            return undefined;
        }

        const sizes = <string[]>Object.values(ButtonSize);

        return sizes.includes(size) ? size : undefined;
    }

    set size(value: string | undefined) {
        if (!value) {
            this.classList.remove(`nh-button--${this.size}`);
            this.removeAttribute('size');
            return;
        }

        const size = <string[]>Object.values(ButtonSize);

        if (!size.includes(value)) {
            return;
        }

        this.setAttribute('size', value);
        this.classList.add(`nh-button--${value}`);
    }

    get icon(): string {
        return this.getAttribute('icon') ?? '';
    }

    set icon(value: string) {
        if (this.#icon && value) {
            this.setAttribute('icon', value);
        }
    }

    get loading(): boolean {
        return this.hasAttribute('loading');
    }

    set loading(value: boolean) {
        this.toggleAttribute('loading', value);
    }

    get disabled(): boolean {
        return this.hasAttribute('disabled');
    }

    set disabled(value: boolean) {
        this.toggleAttribute('disabled', value);
        if (value) {
            this.setAttribute('aria-disabled', value.toString());
            return;
        }
        this.removeAttribute('aria-disabled');
    }

    get action(): boolean {
        return this.hasAttribute('action');
    }

    set action(value: boolean) {
        this.toggleAttribute('action', value);
        this.classList.toggle('nh-button--danger', value);
    }

    constructor(props?: ButtonProps) {
        super();

        this.#setProps(props ?? this);
    }

    protected connectedCallback(): void {
        this.#icon = this.querySelector('.nh-icon') ?? null;
    }

    protected attributeChangedCallback(name: string): void {
        if (name === 'icon') {
            if (!this.#icon) return;

            if (this.#icon.tagName === 'DIV') {
                const iconElement = this.#icon.querySelector('span');
                if (!iconElement) {
                    return;
                }

                iconElement.className = `nh-icon__${this.icon}`;
                return;
            }

            this.#icon.name = this.icon;
        }
    }

    #setProps(props: ButtonProps | Button): void {
        this.variant = props.variant || ButtonVariant.PRIMARY;
        this.loading = props.loading || false;
        this.disabled = props.disabled || false;
        this.size = props.size;
    }
}

if (!window.customElements.get('nh-button')) {
    window.customElements.define('nh-button', Button, { extends: 'button' });
}
